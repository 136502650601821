<script setup lang="ts">
import type { EditorialDiscoverProps } from './EditorialDiscover.props'
const props = defineProps<EditorialDiscoverProps>()

const imagePaddingOptions = {
  s: `${props.carousel || props.cards.length > 4 ? 'pl-0' : 'px-4'}`,
  m: `${props.carousel || props.cards.length > 4 ? 'pl-0' : 'px-8'}`,
  l: `${props.carousel || props.cards.length > 4 ? 'pl-0' : 'px-16'}`,
}

const textPaddingOptions = {
  s: 'px-4',
  m: 'px-8',
  l: 'px-16',
}

const textPositionOptions = {
  top: 'order-1',
  bottom: 'order-2',
}

const cardsPositionOptions = {
  top: 'order-2',
  bottom: 'order-1',
}
</script>

<template>
  <div v-if="isDoubleContent" class="editorial-discover-double-content">
    <EditorialCard
      v-for="(card, index) in cards"
      :key="`${card}-${index}`"
      v-bind="card"
      :aspect-ratio="aspectRatio"
      class="h-full w-full"
    />
  </div>
  <div
    v-else
    class="editorial-discover bg-background-secondary flex w-full flex-col gap-6 py-16"
  >
    <UITitleStrip
      v-if="titleStrip"
      v-bind="titleStrip"
      :custom-class="`${textPosition === 'bottom' ? 'pb-0' : 'pb-6'}`"
      :class="[textPositionOptions[textPosition ?? 'top']]"
      class="positive-margin"
    />
    <div
      :class="[
        [cardsPositionOptions[textPosition ?? 'top']],
        carousel ? 'px-0' : imagePaddingOptions[padding ?? 's'],
      ]"
    >
      <UISliderEditorialWithArrows
        :items="cards"
        :has-no-space-between="cardsConfiguration?.hasNoSpaceBetween"
        :loop="cards.length > 3"
        :show-navigation="cards.length > 3"
        :type="cardsConfiguration?.type"
        :card-dimensions="cardsConfiguration?.cardDimensions"
        :mobile-centered="cardsConfiguration?.mobileCentered"
        :padding="cardsConfiguration?.padding"
      >
        <template #default="{ item }">
          <EditorialCard
            v-bind="item"
            :aspect-ratio="aspectRatio"
            class="h-full w-full"
          />
        </template>
      </UISliderEditorialWithArrows>
    </div>
  </div>
</template>

<style scoped lang="scss">
.set-slides-per-view {
  width: 66.66%;
  @screen lg {
    width: 40%;
  }
  @screen lg-custom {
    width: 25%;
  }
}

.set-slide-per-view-3-cards {
  @screen lg-custom {
    width: 33%;
  }
}
</style>
